import React, { useEffect, useMemo, useRef, useState } from 'react';
import NavigationBar from '../components/NavigationBar';
import CreatorListItem from '../components/CreatorListItem';
import SearchDivider from '../components/SearchDivider';
import GameListItem from '../components/GameListItem';
import TagButtonGroup, {
  TagButtonGroupTitle,
} from '../components/TagButtonGroup';
import styled from '@emotion/styled';
import Layout, { ContentFill } from '../components/Layout';
import { navigate, PageProps } from 'gatsby';
import { StyledLink } from '../components/styled';
import { ChevronRight } from '../assets/icons';
import EmptyState from '../components/EmptyState';
import { fetchCreators, fetchGames } from '../api';
import { useInView } from 'react-cool-inview';
import { css } from '@emotion/react';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import LoadingSpinner from '../components/LoadingSpinner';
import FloatingUpButton from '../components/FloatingUpButton';
import { useTranslation } from 'react-i18next';
import useIsClient from '../hooks/useIsClient';

const SearchResultTitle = styled.h2`
  font-size: 2.25rem;
  line-height: 3.25rem;
  font-weight: 700;
`;

export default function SearchPage({ location }: PageProps) {
  const [gameResults, setGameResults] = useState<ProjectResponse>();
  const [creatorResults, setCreatorResults] = useState<CreatorResponse>();

  const { t } = useTranslation();

  const genres = [
    t('tags:thriller'),
    t('tags:horror'),
    t('tags:fantasy'),
    t('tags:comic'),
    t('tags:historic'),
    t('tags:detective'),
    t('tags:drama'),
    t('tags:touching'),
    t('tags:sci-fi'),
    t('tags:educational'),
  ];

  const places = [
    t('tags:Seoul'),
    t('tags:Gyeonggi'),
    t('tags:Gangwon'),
    t('tags:Busan'),
    t('tags:Gyeongbuk'),
    t('tags:Jeju'),
    t('tags:Daejeon'),
    t('tags:Incheon'),
    t('tags:Ulsan'),
    t('tags:Jeonju'),
    t('tags:Gwangju'),
    t('tags:Namwon'),
  ];

  const keyword = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('keyword') ?? undefined;
  }, [location.search]);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const isClient = useIsClient();

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }

    if (typeof keyword === 'string') {
      fetcher();
    }

    async function fetcher() {
      setIsLoading(true);

      setGameResults(
        await fetchGames({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          keyword: keyword!,
          page: 0 + 1,
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setCreatorResults(await fetchCreators({ keyword: keyword!, take: 2 }));

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useDidUpdateEffect(() => {
    if (typeof keyword === 'string' && page > 0) {
      fetchGames({ keyword, page: page + 1 }).then((response) => {
        setGameResults(
          (gameResults) =>
            gameResults && {
              ...gameResults,
              projects: gameResults.projects.concat(response.projects),
            }
        );
      });
    }
  }, [page]);

  function onSearch(
    keyword: string,
    type: 'text_input' | 'tag' = 'text_input'
  ) {
    if (ref.current) {
      ref.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    // gtag('event', type === 'text_input' ? 'click_search' : 'click_search_tag', {
    //   userID: window.Realworld.getUserId(),
    //   keyword: keyword || 'blank',
    // });

    setPage(0);
    navigate(`/?keyword=${keyword}`, { replace: true });
  }

  const { observe } = useInView({
    onEnter: () => {
      if (gameResults) {
        setPage((page) => (page < gameResults.totalPages ? page + 1 : page));
      }
    },
  });

  const count = (creatorResults?.count ?? 0) + (gameResults?.count ?? 0);

  if (keyword === undefined)
    return (
      <Layout>
        <NavigationBar type="search" keyword={keyword} onSearch={onSearch} />
        {/* Hack: 하이드레이션이 엉키는 문제 때문에 다른 태그 이름을 사용하게 되었습니다. */}
        {/* Hack: key 를 주입하지 않으면 영문으로 바뀌지 않음. */}
        <ContentFill as="section" key={isClient.key}>
          <TagButtonGroupTitle>{t('search_by_genre')}</TagButtonGroupTitle>
          <TagButtonGroup
            css={{ marginBottom: 'var(--xlarge4)' }}
            onClick={onSearch}
            tags={genres}
          />
          <TagButtonGroupTitle>{t('search_by_place')}</TagButtonGroupTitle>
          <TagButtonGroup onClick={onSearch} tags={places} />
        </ContentFill>
      </Layout>
    );

  if (creatorResults?.count === 0 && gameResults?.count === 0)
    return (
      <Layout>
        <NavigationBar type="search" keyword={keyword} onSearch={onSearch} />
        <ContentFill>
          <SearchResultTitle>
            {t('search_result', { count, keyword })}
          </SearchResultTitle>
          <EmptyState />
        </ContentFill>
      </Layout>
    );

  const isSearched =
    typeof keyword === 'string' && !!creatorResults && !!gameResults;

  return (
    <Layout>
      <NavigationBar type="search" keyword={keyword} onSearch={onSearch} />
      <ContentFill ref={ref}>
        {isSearched && (
          <SearchResultTitle>
            {keyword.length > 0
              ? t('search_result', { keyword, count })
              : t('search_result_all', { count })}{' '}
          </SearchResultTitle>
        )}
        <section
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 'var(--xlarge)',
          }}
        >
          <div className="results-channels">
            {creatorResults?.creators.map((creator) => (
              <CreatorListItem creator={creator} key={creator.id} />
            ))}
          </div>
          {creatorResults && creatorResults.count > 2 && (
            <StyledLink
              css={{
                alignSelf: 'flex-end',
                marginTop: 'var(--large)',
                display: 'flex',
              }}
              to={`/creators?keyword=${keyword}`}
              state={{ previousPathname: location.pathname }}
            >
              {t('all_creator')}{' '}
              <ChevronRight
                css={{
                  marginLeft: 'var(--xsmall)',
                  width: '2rem',
                  height: '2rem',
                  alignSelf: 'center',
                }}
              />
            </StyledLink>
          )}
          {creatorResults &&
            creatorResults?.count > 0 &&
            gameResults &&
            gameResults.projects.length > 0 && (
              <SearchDivider
                css={{
                  margin: 'var(--xlarge2) calc(-1 * var(--large))',
                }}
              />
            )}
        </section>
        <ul
          className="result-games"
          css={css`
            > li:not(:last-of-type) {
              border-bottom: 1px solid #f4f4f4;
              margin-bottom: var(--large);
            }
          `}
        >
          {gameResults?.projects.map((game, index) => (
            <li css={{ listStyle: 'none' }} key={game.id}>
              <GameListItem
                game={game}
                ref={
                  index === gameResults.projects.length - 1
                    ? observe
                    : undefined
                }
              />
            </li>
          ))}
        </ul>
        {isLoading && <LoadingSpinner />}
        <FloatingUpButton scrollContainerRef={ref} />
      </ContentFill>
    </Layout>
  );
}
