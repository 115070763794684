import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

interface TagButtonGroupProps
  extends Omit<React.ComponentPropsWithoutRef<'ul'>, 'onClick'> {
  tags: string[];
  onClick(tag: string, type: 'text_input' | 'tag'): void;
}

const tagButtonGroupStyle = css`
  list-style: none;

  display: flex;
  flex-wrap: wrap;

  margin-top: calc(-1 * var(--xsmall));

  > li {
    margin-right: var(--xsmall);
    margin-top: var(--xsmall);
  }
`;

const TagButton = styled.button`
  background-color: #f4f4f4;
  border-radius: 16px;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: var(--xlarge);
  color: #626262;
  padding: 0.75rem var(--medium);
`;

export default function TagButtonGroup({
  tags,
  onClick,
  ...rest
}: TagButtonGroupProps) {
  return (
    <ul role="group" css={tagButtonGroupStyle} {...rest}>
      {tags.map((tag) => (
        <li key={tag}>
          <TagButton onClick={() => onClick(tag, 'tag')}>{tag}</TagButton>
        </li>
      ))}
    </ul>
  );
}

export const TagButtonGroupTitle = styled.h2`
  font-weight: 400;
  font-size: 1.75rem;
  line-height: var(--xlarge);

  margin-bottom: var(--large);
`;
