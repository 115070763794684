import styled from '@emotion/styled';

const SearchDivider = styled.hr`
  height: var(--small);

  border: none;
  background: #f4f4f4;
`;

export default SearchDivider;
