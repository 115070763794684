import { t } from 'i18next';

export const newPriceToString = (
  price: number,
  currency: string,
  applicationId: string,
  kitRequired?: boolean
) => {
  const isSeongsuProject = applicationId === '_Z9bqXKmpuDn9jMPH0tYmQ';
  const priceString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const isChurro = currency === 'Churro';

  if (kitRequired || isSeongsuProject) {
    return `${price.toLocaleString()} ${t('won')}`;
  }

  if (isChurro) {
    return `${priceString} ${t('churro')}`;
  }

  return `${priceString} ${t('won')}`;
};

export const filterPrice = (project: {
  originalPrice?: number;
  price?: number;
  originalPointPrice?: number;
  pointPrice?: number;
  priceDisplay?: boolean;
  kitRequired?: boolean;
  applicationId: string;
}) => {
  const {
    originalPrice,
    price,
    originalPointPrice,
    pointPrice,
    priceDisplay,
    kitRequired,
    applicationId,
  } = project;

  const isPointValue =
    pointPrice !== undefined &&
    pointPrice !== null &&
    originalPointPrice !== undefined &&
    originalPointPrice !== null &&
    (originalPointPrice > 1 || pointPrice > 1);

  const isValue =
    (originalPointPrice === 0 || originalPointPrice === 1) &&
    (pointPrice === 0 || pointPrice === 1);

  const isPointDiscount: boolean =
    !!originalPointPrice && !!pointPrice && !isValue;
  const isDiscount: boolean = !!originalPrice && !!price && !isPointValue;
  const currency = isPointValue ? 'Churro' : 'Krw';

  let discount;
  if (isPointDiscount) {
    discount = `${Math.round(
      100 - ((pointPrice ?? 1) / (originalPointPrice ?? 1)) * 100
    )}%`;
  } else if (isDiscount) {
    discount = `${Math.round(
      100 - ((price ?? 1) / (originalPrice ?? 1)) * 100
    )}%`;
  } else {
    discount = null;
  }

  let priceValue = '';
  if (priceDisplay) {
    if (kitRequired) {
      if (price) {
        priceValue = newPriceToString(
          price,
          currency as string,
          applicationId,
          kitRequired
        );
      } else {
        priceValue = t('free');
      }
    } else if (pointPrice) {
      priceValue = newPriceToString(
        pointPrice,
        currency as string,
        applicationId,
        kitRequired
      );
    } else {
      priceValue = t('free');
    }
  }

  let originalPriceValue = '';
  if (priceDisplay) {
    if (kitRequired) {
      if (originalPrice) {
        originalPriceValue = newPriceToString(
          originalPrice,
          currency as string,
          applicationId,
          kitRequired
        );
      } else {
        originalPriceValue = '';
      }
    } else if (originalPointPrice) {
      originalPriceValue = newPriceToString(
        originalPointPrice,
        currency as string,
        applicationId,
        kitRequired
      );
    } else {
      originalPriceValue = '';
    }
  }

  return {
    discount,
    price: priceValue,
    originalPrice: originalPriceValue,
  };
};
